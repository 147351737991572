import { ButtonIconPositionHugLeft, ButtonAlignCenter, ButtonFlashRadial, ButtonIconActiveHeight, ButtonIconActiveWidth } from '@kit/components/IconButton.vue'

  export const orangeButton = {
    foreground:"#FFFFFF",
    background: {
      active:"#F16034",
      borderRadius:"10px",
      padding:"10px",
      hover:"#e67829"
    },
    flash: {
      style: ButtonFlashRadial,
      fill:"#e6a729"
    },
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }

  export const signupButton = {
    ...orangeButton,
    background: {
      active:"#F16034",
      borderRadius:"0px 20px 20px 0px",
      padding:"10px 10px 10px 30px",
      hover:"#e67829"
    },
  }

  export const blueButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#FFFFFF",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    background: {
      active:       "#49ADAB",
      inactive:     "#CCCCCC",
      flash:        "#42b6cf", 
      loading:      "#CCCCCC",
      hover:        "#388fa1",
      borderRadius: "10px",   
      padding:      "10px"     
    },
    iconPosition: ButtonIconPositionHugLeft,
    align: ButtonAlignCenter,
    flash: ButtonFlashRadial,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }

  export const navMainSearchButton = { ...blueButton, 
    icon:"rounded-magnifying-glass-negative",
    outerClasses:"sb sb-explicit sths-nav-main-search-iconbutton",
    foreground: "#FFFFFF",
    background: {  
      ...blueButton.background,
      padding:      "0px", 
      borderRadius: "0px"    
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '40px'
    },
  }

  export const navSearchWidgetButton = { 
    ...blueButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb-explicit sths-nav-main-search-widget-button",
    foreground: "#FFFFFF",
    background: {  
      ...blueButton.background,
      borderRadius: "4px",  
      padding:      "5px",   
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '20px'
    },
  }

  export const prevNextSearchResultsButton = { 
    ...blueButton,
    background: { 
      ...blueButton.background, 
      padding:"7px 20px 7px 20px",
      borderRadius:"3px"
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '25px'  
    } 
  }
  


  export const roundCloseButton = {
    ...blueButton, 
    icon:"rounded-close-x_50", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    },
    background: {
      ...blueButton.background,
      borderRadius: "50%"   
    },
  }


  export const donateTopNavButton = {...blueButton, 
    
    iconFromProject:true, 
    icon:'cc',  
    
    background:{
      ...blueButton.background, 
      padding:'5px 10px 5px 10px'
    },

    iconDimension: {
      style: ButtonIconActiveWidth,
      active: "30px"
    }

  }

  export const donateStarPageButton = {
    ...donateTopNavButton,
    background: {
      ...donateTopNavButton.background,
      padding:"15px"
    }
  }

  export const donateSideNavButton = { 
    ...orangeButton, 
  
    iconFromProject:true, 
    icon:'cc',  

    iconDimension: {
      style: ButtonIconActiveWidth,
      active: "40px"
    }
  }


  export const socialMediaButton = {
    foreground: "#163E6F",
    background: {
      active:       "#849CB3",
      inactive:     "#CCCCCC",
      flash:        "#42b6cf", 
      loading:      "#CCCCCC",
      hover:        "#388fa1",
      borderRadius: "50%",   
      padding:      "3px"     
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '50px'
    },
    iconFromProject: true,
    flash: ButtonFlashRadial,
    allowPropagate: false,
  }