<script>
import { defineComponent, inject, computed } from "vue"
import NavMainSearch from "@kit/components/NavMainSearch.vue"
import { searchWidgetMain, navbar, donateTopNavButton } from "@project/themes"
import NavBarBase from "@kit/components/NavBarBase.vue"
import IconButton from "@kit/components/IconButton.vue"
import { getEnv } from "@kit/utils/EnvironmentHelper"


export default defineComponent({
  name: "NavBar",
  setup() {
    const hydrate = inject('hydrate')

    const themer = inject('themer')
    const navbarTheme = themer(navbar)
    const searchWidgetMainTheme = themer(searchWidgetMain)
    const donateTopNavButtonTheme = themer(donateTopNavButton)


    const { prefetch } = hydrate({
      prefetch:["common"]
    })

    const donationLink = computed(() => {
      return getEnv("STRIPE_DONATION_LINK")
    })

    const common = computed(() => {
      return prefetch.value ? prefetch.value.common : null
    })

    return { searchWidgetMainTheme, navbarTheme, donateTopNavButtonTheme, common, donationLink };
  },
  components: {
    NavMainSearch,
    NavBarBase,
    IconButton
  },
});
</script>

<style>

/* Navbar CSS 
*/

.nav-top-option {
  cursor: pointer;
  padding:20px;
  z-index:2;
}
.nav-top-option:not(.blue1-bg) {
  background:white;
}
.nav-top-option:hover {
  background:#EEEEEE;
}
.nav-top-option.blue1-bg:hover {
  background:#082850
}
.navbar-subitems {
  background:#EEEEEE;
} 
.navbar-subitems > .sb{
  background: #EEEEEE;
  color: #555555;
  padding:5px 15px 5px 15px;
  font-weight: normal;
}
.navbar-subitems > .sb:hover {
  background: #082850;
  color: #FFFFFF
}
.blue-nav > li > a {
  color:white; 
  font-weight:normal;
}

.navbar-logo-wrapper {
  padding:5px !important;
}
.navbar-logo-wrapper--attop {
  padding:10px !important;
}

.sths-nav-main-searchoption {
  width:100px !important;
}
.sths-nav-main-search-iconbutton {
  width:40px !important;
}
.sths-nav-main-search-widget-button {
  width:100px !important;
}


/* Search UI CSS */
.sths-search-modal-window {
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  min-height: 335px;
  padding-top: 107px;
  padding-bottom: 50px;
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(257deg, rgba(19, 95, 129, 0.68), rgba(6, 42, 70, 0.73));
  opacity: 1;
}

.sths-main-search-widget-headline {
  font-size:1.2em;
}

input.nav-mobile-search-input:focus, input.nav-main-search-input:focus, input.nav-main-search-nonmodal-input:focus  {
  background-color:#ebfcfc !important;
}

input.nav-mobile-search-input, input.nav-main-search-input, input.nav-main-search-nonmodal-input {
  border-style: none none solid;
  background-color: #f8f8f8;
  font-family: Oswald, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding:10px !important;
}
input.nav-main-search-nonmodal-input {
  border: 2px solid #49ADAB;
  border-radius: 3px;
}
@media (max-width : 960px) {
  input.nav-mobile-search-input {
    font-size: 25px;
  }
}

.nav-main-search-ui-container {
  background-color:white;
  border-radius:5px;
}
.nav-mobile-search-ui-container {
  background-color:white;
  border-radius:5px;
}

.nav-main-search-nonmodal-completion-ui-container {
  box-shadow: 0px 5px 5px rgba(0,0,0, 0.5); 
}

/*modal styles*/
.sths-search-results-prevnext-wrapper {
  padding:0px !important;
  margin-top:10px !important;
}
.sths-search-result {
  padding: 0px !important;
}
.sths-results-block {
  padding:10px !important;
  background: #F2F2F2;
}


/*nonmodal widget*/
.sths-search-modal-search-nonmodal-button {
  width: 50px !important;
}
.sths-search-nonmodal-headline {
  font-weight:400;
  color: var(--dark-text);
}

/*styles for the mobile side-nav drawer ui*/
.sths-mobile-search-button {
  width: 50% !important;
}
.sths-mobile-search-button svg {
  transform: rotate(45deg);
}
.sths-mobile-search-headline {
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  color:white; 
}
.sths-mobile-search-wrapper {
  padding-top:20px;
  padding-right:20px;
}
.sths-mobile-search-completion-wrapper, .sths-main-search-completion-wrapper  {
  color:black;
  font-size: 20px;
}
.sths-mobile-completion-result {
  padding:7px;
}
.sths-main-completion-result {
  padding:7px;
  font-size: 18px;
}
.sths-mobile-completion-result:hover, .sths-main-completion-result:hover  {
  background-color:#EEEEEE;
}



</style>

<template>
  <NavBarBase :theme="navbarTheme">
    <template v-slot:nav-items="{ navItemClasses, dropDownNavItemClasses, navSubItemClasses }">

        <div :class="dropDownNavItemClasses">
          Services
          <div v-if="common" :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" style="font-size:1.2em;">
            <router-link v-for="service in common.services" class="sb" style="white-space:nowrap;" :to="`/services/${service.url_slug}`">{{ service.display_title }}</router-link>
          </div>
        </div>
        <div :class="dropDownNavItemClasses">
          Locations
          <div v-if="common" :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" style="font-size:1.2em;">
            <router-link v-for="location in common.locations" class="sb" style="white-space:nowrap;" :to="`/locations/${location.post_name}`">{{ location.nav_title }}</router-link>
          </div>
        </div>
        <div :class="dropDownNavItemClasses">
          About Us
          <div v-if="common" :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" style="font-size:1.2em;">
            <router-link class="sb" style="white-space:nowrap;" to="/about-us">General Info</router-link>
            <router-link v-for="team in common.teams" class="sb" style="white-space:nowrap;" :to="`/team/${team.post_name}`">{{ team.nav_title }}</router-link>
            <router-link class="sb" style="white-space:nowrap;" to="/news">News</router-link>
          </div>
        </div>
        <div :class="dropDownNavItemClasses">
          Employees
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" style="font-size:1.2em;">
            <a class="sb sb-text" href="https://otis.osmanager4.com/sthsclinic" style="white-space:nowrap;">Training Portal</a>
            <router-link class="sb" style="white-space:nowrap;" :to="`/job-openings`">Job Openings</router-link>
          </div>
        </div>
        <div :class="dropDownNavItemClasses">
          STAR
          <div :class="navSubItemClasses" aria-role="menu" aria-orientation="vertical" style="font-size:1.2em;">
            <router-link class="sb" style="white-space:nowrap;" :to="`/star`">About STAR</router-link>
            <router-link class="sb" style="white-space:nowrap;" :to="`/star-events`">STAR Events</router-link>
            <div class="sb">
              <IconButton  
              text="Donate"
              target="_blank"
              :theme="donateTopNavButtonTheme"
              :href="donationLink"/>
            </div>
          </div>
        </div>

      
        <!-- <div class="sb sb-h sb-align-cv navbar-item navbar-dropdown nav-option">
          Services
          <div v-if="common" class="sb sb-v navbar-subitems nav-sub-options" style="font-size:1.2em;">
            <router-link v-for="service in common.services" class="sb" style="white-space:nowrap;" :to="`/services/${service.url_slug}`">{{ service.display_title }}</router-link>
            <router-link class="sb" style="white-space:nowrap;" to="/covid-19">Covid-19</router-link>
          </div>
        </div>
        <div class="sb sb-h sb-align-cv navbar-item navbar-dropdown nav-option">
          Locations
          <div v-if="common" class="sb sb-v navbar-subitems nav-sub-options" style="font-size:1.2em;">
            <router-link v-for="location in common.locations" class="sb" style="white-space:nowrap;" :to="`/locations/${location.post_name}`">{{ location.nav_title }}</router-link>
          </div>
        </div>
        <div class="sb sb-h sb-align-cv navbar-item navbar-dropdown nav-option">
          About Us
          <div v-if="common" class="sb sb-v navbar-subitems nav-sub-options" style="font-size:1.2em;">
            <router-link class="sb" style="white-space:nowrap;" to="/about-us">General Info</router-link>
            <router-link v-for="team in common.teams" class="sb" style="white-space:nowrap;" :to="`/team/${team.post_name}`">{{ team.nav_title }}</router-link>
            <router-link class="sb" style="white-space:nowrap;" to="/news">News</router-link>
          </div>
        </div>
        <div class="sb sb-h sb-align-cv navbar-item navbar-dropdown nav-option">
          Employees
          <div class="sb sb-v navbar-subitems nav-sub-options" style="font-size:1.2em;">
            <a class="sb sb-text" href="https://otis.osmanager4.com/sthsclinic" style="white-space:nowrap;">Training Portal</a>
            <router-link class="sb" style="white-space:nowrap;" :to="`/job-openings`">Job Openings</router-link>
          </div>
        </div>
        <div class="sb sb-h sb-align-cv navbar-item navbar-dropdown nav-option">
          STAR
          <div class="sb sb-v navbar-subitems nav-sub-options" style="font-size:1.2em;">
            <router-link class="sb" style="white-space:nowrap;" :to="`/star`">About STAR</router-link>
            <router-link class="sb" style="white-space:nowrap;" :to="`/star-events`">STAR Events</router-link>
            <div class="sb">
              <IconButton  
              text="Donate"
              target="_blank"
              :theme="donateTopNavButtonTheme"
              :href="donationLink"/>
            </div>
          </div>
        </div> -->


        <NavMainSearch :theme="searchWidgetMainTheme" />
        <a class="sb sb-h sb-align-cv nav-top-option blue1-bg white" style="white-space:nowrap;" href="https://portal.we-care.org/general/">Patient Portal +</a>
      

    </template>
  </NavBarBase>
</template>
