<script>
import { defineComponent, inject, computed } from "vue";
import SidebarRouteLink from "@kit/components/SidebarRouteLink.vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import SideNavDrawerBase from "@kit/components/SideNavDrawerBase.vue";
import { searchWidgetMobile, sideDrawer, orangeButton, donateSideNavButton  } from "@project/themes";
import IconButton from '@kit/components/IconButton.vue'
import { getEnv } from '@kit/utils/EnvironmentHelper'
import { getCurrentYear } from '@kit/utils/Formats'


export default defineComponent({
  name: "SizeNavDrawer",
  setup() {
    const hydrate = inject("hydrate")
    const themer = inject("themer")

    const searchWidgetMobileTheme = themer(searchWidgetMobile)
    const sideDrawerTheme = themer(sideDrawer)
    const orangeButtonTheme = themer(orangeButton)
    const donateSideNavButtonTheme = themer(donateSideNavButton)
    

    const { prefetch } = hydrate({
      prefetch:["common"]
    })

    const common = computed(() => {
      return prefetch.value ? prefetch.value.common : null
    })

    const donationLink = computed(() => {
      return getEnv("STRIPE_DONATION_LINK")
    })

    return { searchWidgetMobileTheme, sideDrawerTheme, common, donationLink,
    orangeButtonTheme, donateSideNavButtonTheme, getCurrentYear };

  },
  components: {
    SideNavDrawerBase,
    SidebarRouteLink,
    NavMainSearch,
    IconButton
  },
});
</script>

<style>

.sths-side-nav-drawer {
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(
    257deg,
    rgba(19, 95, 129, 1),
    rgba(6, 42, 70, 1)
  );
  opacity: 1;
}

.sths-sidenav-item {
  color:white;
  font-size:1.5rem !important;
}
.sths-sidenav-little-icon {
  height:1.5rem !important;
}

</style>

<template>
  <SideNavDrawerBase :theme="sideDrawerTheme">
    <template #nav-items="{ active }">

      <ul class="sb sb-v">
          <li class="sb sths-sidenav-item">
            <a class="white" href="https://portal.we-care.org/general/">Patient Portal</a>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/services/primary-care" style="color:white;">Primary Care</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/services/dental-services" style="color:white;">Dental Services</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/services/behavioral-health" style="color:white;">Behavioral Health</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/about-us" style="color:white;">About</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/news" style="color:white;">News</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/job-openings" style="color:white;">Careers</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <a class="white" href="https://otis.osmanager4.com/sthsclinic">Training Portal</a>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/star" style="color:white;">About STAR</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">
            <SidebarRouteLink to="/star-events" style="color:white;">STAR Events</SidebarRouteLink>
          </li>
          <li class="sb sths-sidenav-item">         
            <IconButton  
            style="flex:0 1 auto; padding-left:40px; padding-right:40px"
            text="Donate to STAR"
            target="_blank"
            :theme="donateSideNavButtonTheme"
            :href="donationLink"/>
          </li>
        </ul>

        <ul class="sb sb-v">
          <template v-if="common" v-for="location in common.locations">
            <li class="sb sb-h sb-alt2 sb-align-cv">
              <div class="sb sths-sidenav-little-icon">
                <img src="@images/STHS-WeCare-footer-icons_map-drop.png" alt="Map geocode icon" style="height:100%; width:auto; object-fit:contain;">
              </div>
              <router-link class="sb sths-sidenav-item" style="margin-left:5px; color:white;" :to="`/locations/${location.post_name}`">{{ location.nav_title }}</router-link>
            </li>
            <li class="sb sb-h sb-alt2 sb-align-cv" style="margin-top:5px;">
              <div class="sb sths-sidenav-little-icon">
                <img src="@images/STHS-WeCare-footer-icons_phone.png" alt="Phone icon" style="height:100%; width:auto; object-fit:contain;">
              </div>
              <span class="sb sths-sidenav-item" style="margin-left:5px;">{{location.phone_number}}</span>
            </li>
          </template>
 
        </ul>

      <NavMainSearch :theme="searchWidgetMobileTheme" :active="active" />
      
      <div class="sb sb-text sths-sidenav-item">
        © {{getCurrentYear()}} WeCare. All rights reserved
      </div>

    </template>
  </SideNavDrawerBase>
</template>
