import { common } from '@project/api/common/v1'
import { home } from '@project/api/home/v1'
import { star, starEvents } from '@project/api/star/v1'
import { bySlug as getTeam } from '@project/api/team/v1'
import { byID as getContact } from '@/project/api/contact/v1'
import { byID as getJob } from '@/project/api/job-opening/v1'
import { paginate as paginateNews, total as totalNews } from '@project/api/news/v1'
import { paginate as paginateJobs, total as totalJobs } from '@project/api/job-opening/v1'
import { aboutUs } from '@project/api/about-us/v1'
import { covid19 } from '@project/api/covid-19/v1'
import { bySlug as getNews } from '@/project/api/news/v1'

export default {
  async common() {
    return await common()
  },
  async home() {
    return await home()
  },
  async team(params) {
    return getTeam(params.teamSlug, false)
  },
  async contact(id) {
    return getContact(id)
  },
  async star() {
    return star()
  },
  async jobs({ page, numberPerPage }) {
    return paginateJobs("created", "DATE", "DESC", page, numberPerPage)
  },
  async job(id) {
    return getJob(id)
  },
  async totalJobs() {
    return totalJobs()
  },
  async news({ page, numberPerPage }) {
    return paginateNews("created", "DATE", "DESC", page, numberPerPage)
  },
  async newsStory(slug) {
    return getNews(slug)
  },
  async totalNews() {
    return totalNews()
  },
  async aboutUs() {
    return aboutUs()
  },
  async covid19() {
    return covid19()
  },
  async starEvents() {
    return starEvents()
  }
}

