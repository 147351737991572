import { get, total as _total, paginate as _paginate } from '@kit/utils/APIV1.js'

export const bySlug = async(slug) => {
  return get(`news/story/${slug}`)
}

export const paginate = async(sortField, cast, direction, page, numberPerPage) => {
  return _paginate('news-stories', sortField, cast, direction, page, numberPerPage)
}

export const total = async() => {
  return _total('news-stories')
}

export const homepageFeatures = async() => {
  return get("homepage-featured-news-stories")
}

export const covid19NewsStories = async() => {
  return get("covid-19-news-stories")
}