import { slugs, pages } from '@kit/utils/APIV1'

export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue'),
  },
  {
    name: 'locations',
    path: '/locations/:id',
    component:() => import(/* webpackChunkName: "locations" */'@pages/Location.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('locations')
        }
      }
    }
  },
  {
    name: 'health-services',
    path: '/services/:id',
    component:() => import(/* webpackChunkName: "health-services" */'@pages/HealthServices.vue'),
    meta: {
      paramsMappedToMultipleSections:true,
      sitemap: {
        slugs: async() => {
          return await slugs('health-services')
        }
      }
    }
  },
  {
    name: 'job-openings',
    path: '/job-openings/:page?',
    component:() => import(/* webpackChunkName: "job-openings" */'@pages/JobOpenings.vue'),
    meta: {
      paramsMappedToMultipleSections:true,
      sitemap: {
        slugs: async() => {
          return await pages('job-openings')
        }
      }
    }
  },
  {
    name: 'job',
    path: '/job-openings/job/:id',
    component:() => import(/* webpackChunkName: "job" */'@pages/Job.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('job-openings')
        }
      }
    }
  },
  {
    name: 'team',
    path: '/team/:team',
    component:() => import(/* webpackChunkName: "team" */'@pages/Team.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('teams')
        }
      }
    }
  },
  {
    name: 'contact',
    path: '/contact/:id',
    component: () => import(/* webpackChunkName: "contact" */'@pages/Contact.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('contacts')
        }
      }
    }
  },
  { 
    name: 'about-us',
    path: '/about-us',
    component: () => import(/* webpackChunkName: "about-us" */'@pages/AboutUs.vue')
  },
  {
    name: 'news',
    path: '/news/:page?',
    component: () => import(/* webpackChunkName: "news" */'@pages/NewsStories.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await pages('news-stories')
        }
      }
    }
  },
  {
    name: 'news-story',
    path: '/news/story/:slug',
    component: () => import(/* webpackChunkName: "news-story" */'@pages/NewsStory.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('news-stories')
        }
      }
    }
  },
  {
    name: 'covid-19',
    path: '/covid-19',
    component: () => import(/* webpackChunkName: "covid-19" */'@pages/Covid19.vue')
  },
  {
    name: 'star',
    path: '/star',
    component: () => import(/* webpackChunkName: "star" */'@pages/Star.vue'),
  },
  {
    name: 'star-events',
    path: '/star-events',
    component: () => import(/* webpackChunkName: "star-events" */'@pages/StarEvents.vue'),
  },


  // {
  //   name: 'star-event',
  //   path: '/star/event/:slug',
  //   component: () => import('@pages/StarEvent.vue'),
  //   meta: {
  //     sitemap: {
  //       slugs: () => {
  //         //this has to provide an array of slugs.
  //         return ['event-work-you-darn-thing']
  //       }
  //     }
  //   }
  // },
  // {
  //   name: 'volunteer',
  //   path: '/volunteer',
  //   component: () => import('@pages/Volunteer.vue')
  // },
  {
    name: 'donate',
    path: '/donate',
    component: () => import(/* webpackChunkName: "donate" */'@pages/Donate.vue')
  },
]
