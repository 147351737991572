
import { pathname } from '@kit/utils/Formats'
import "@images/STHS-WeCare-logo_tagline.png" 

export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  subitemsRevealDuration: 0,

  //the mergeable classes for the outer fixed wrapper
  outerContainerClassesDesktop: "sths-nav-outer",
  outerContainerClassesMobile: "sths-nav-outer",

  logoWrapperClasses:"sths-logo-wrapper",

  //the logo and aria text
  logoSrc:pathname("@images/STHS-WeCare-logo_tagline.png"),
  logoAriaAlt:"WeCare navigation logo",

  //required
  height: "90px",
  shrinkHeight: "50px",

  padding: "14px",
  shrinkPadding: "5px",

  //optional. The top gap
  topGap: "0px"
    
}

export const sideDrawer = {
  topNavHeight:"90px",
  outerClasses:"sths-side-nav-drawer"
}