import { get, total as _total, paginate as _paginate } from '@kit/utils/APIV1.js'

export const byID = async(id) => {
  return get(`job-opening/id/${id}`)
}

export const paginate = async(sortField, cast, direction, page, numberPerPage) => {
  return _paginate('job-openings', sortField, cast, direction, page, numberPerPage)
}
export const total = async() => {
  return _total('job-openings')
}
